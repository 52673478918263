import '@/scss/styles.scss';
import '@/scss/global.scss';

import Vue from 'vue';
import App from './App.vue';
import Router from 'vue-router';
import router from './router';
import Buefy from 'buefy';
import vueDebounce from 'vue-debounce';
Vue.use(Buefy);
Vue.use(Router);
Vue.use(vueDebounce, {
  defaultTime: '1000ms',
});

Vue.config.productionTip = false;
new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
