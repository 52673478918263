import { EconomicCalculationType } from '@/utils/constants';
import { EconomicCalculationDetails } from './report/report.model';

export enum MedicineBrand {
  DENEGARD = 'Denegard',
  PULMOTIL = 'Pulmotil',
}

export enum ReferenceType {
  E_STERVERMER = 'E Stervermer Reference',
  PIG_SITE = 'Pig Site Reference',
}

export interface PleurisyCalculatorModalData
  extends EconomicCalculationDetails,
    AssumptionModalData {
  numberOfAnimalHeads: number | null;
  adgValue: number | null;
  weanMarketDays: number | null;
  weekNumber: number | null;
  additionalFeedCostPigRate: number | null;
  adfiValue: number | null;
  liveWeightPrice: number | null;
  targetMarketWeight: number | null;
  averageFeedPrice: number | null;
  referenceType: ReferenceType | null;
  postWeaningMortality: number | null;
  currencySymbol?: string;
  economicCalculationType: EconomicCalculationType | null;
  ageOfWeaning: number | null;
}

export interface PleurisyObject {
  initialWeaningMortality: number | null;
  totalCostPostWeaning: number | null;
  totalCostPostWeaningEdit: number | null;
}

export interface UpdatePleurisyData {
  liveWeightPrice: number | null;
  targetMarketWeight: number | null;
  averageFeedPrice: number | null;
  postWeaningMortality: number | null;
}

export interface AssumptionModalData {
  bronchopneumoniaAssumptionNormalLungsLowPercentage?: number | null;
  bronchopneumoniaAssumptionNormalLungsHighPercentage?: number | null;
  bronchopneumoniaAssumptionLowPneumonia?: number | null;
  bronchopneumoniaAssumptionNormalPneumonia?: number | null;
  bronchopneumoniaAssumptionHighPneumonia?: number | null;
  bronchopneumoniaAssumptionLowSeverePneumonia?: number | null;
  bronchopneumoniaAssumptionHighSeverePneumonia?: number | null;
  p3AndP4PrevalenceAssumption?: number | null;
  appPleurisyPrevalenceAssumption?: number | null;
  postWeaningMortalityAssumption?: number | null;
}

export interface FarmProductionPerformance {
  sowLevel: number | null;
  sowIndex: number | null;
  averageLitterSizeBornAlive: number | null;
  preWeaningMortality: number | null;
  nurseryMortality: number | null;
}

export interface PulmotilEconomicCalculationDetails {
  pneumoniaSeverityDict?: { [key: string]: number };
  appPleurisyPrevalence?: number;
  p3AndP4Prevalence?: number;
}

export interface PulmotilMedicationCalculationDetails {
  liveWeightPrice: number | null;
  targetMarketWeight: number | null;
  averageFeedPrice: number | null;
  // Lactation Feed
  numberOfDaysPreFarrowing: number | null;
  numberOfDaysPostFarrowing: number | null;
  adfiAtGestation: number | null;
  adfiAtLactation: number | null;
  // Nursery Feed
  numberOfDaysFedInNursery: number | null;
  feedConsumptionPerDayInNursery: number | null;
  // Grower Feed
  numberOfDaysFedInGrower: number | null;
  feedConsumptionPerDayInGrower: number | null;
  // Clean Sow Program
  cleanSowMedicineBrand: string | null;
  medicationDoseInCleanSowProgram: number | null;
  costOfMedicationPerKiloInCleanSowProgram: number | null;
  // Early Intervention Program
  earlyInterventionMedicineBrand: string | null;
  medicationDoseInEarlyInterventionProgram: number | null;
  costOfMedicationPerKiloInEarlyInterventionProgram: number | null;
  // Grower Program
  growerMedicineBrand: string | null;
  medicationDoseInGrowerProgram: number | null;
  costOfMedicationPerKiloInGrowerProgram: number | null;
}

export interface PulmotilCalculationModalData
  extends FarmProductionPerformance,
    PulmotilEconomicCalculationDetails,
    PulmotilMedicationCalculationDetails,
    AssumptionModalData {
  numberOfSowsFarrowingPerMonth: number;
  numberOfPigletsBornPerMonth: number;
  numberOfPigletsAtNursery: number;
  numberOfGrowerPigs: number;
  adgValue: number | null;
  weanMarketDays: number | null;
  weekNumber: number | null;
  additionalFeedCostPigRate: number | null;
  adfiValue: number | null;
  referenceType: ReferenceType | null;
  postWeaningMortality: number | null;
  currencySymbol?: string;
  economicCalculationType: EconomicCalculationType | null;
  ageOfWeaning: number | null;
}

export interface UpdateAnimalNumberModalDetails {
  numberOfAnimalHeads: number | null;
  weanMarketDays: number | null;
  weekNumber: number | null;
  ageOfWeaning: number | null;
}

export type EconomicCalculationModalDataType =
  | PleurisyCalculatorModalData // Malaysia Calculation
  | PulmotilCalculationModalData; // Standard Calculation
