import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { serializePaginationFromHeader } from './pagination';
import {cookie,  COOKIE } from './cookie';
import { UserSessionStore } from '@/store/swine/user-session';

export enum HttpCode {
  Unauthorized = 401,
}

const config: AxiosRequestConfig = {
  baseURL: `${process.env.VUE_APP_API_BASE_URL || 'http://localhost:4000'}/${
    process.env.VUE_APP_API_VERSION || 'v1'
  }`,
  timeout: 20000,
  withCredentials: true,
};

const requestInterceptor = (config: AxiosRequestConfig) => {
  return config;
};

const responseInterceptor = () => {
  return (response: AxiosResponse) => {
    return {
      ...response,
      pagination: serializePaginationFromHeader(response.headers),
    };
  };
};

const errorInterceptor = () => {
  return (response: AxiosError) => {
    if (
      response &&
      response.response?.status === HttpCode.Unauthorized &&
      !response.config.url?.includes('/accounts')
    ) {
      cookie.remove(COOKIE.TOKEN);
      UserSessionStore.detail.setNoRedirect(false);
    }
    return response;
  };
};

const instance = Axios.create(config);
instance.interceptors.request.use(requestInterceptor);
instance.interceptors.response.use(responseInterceptor(), errorInterceptor());

export const axios = instance;
