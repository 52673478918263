import {
  Module,
  VuexModule,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import { LoginParams, LoginResponse } from '@/models/swine/login/login.model';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { ToastProgrammatic } from 'buefy';

//TODO: Set up everything once API is done
@Module({
  namespaced: true,
  dynamic: true,
  name: 'login.store',
  store,
})
class LoginStore extends VuexModule {
  response: any | null = null;
  isLoading = false;
  error: any | null = null;
  isLoginInvalid: boolean | null = null;
  isAuthenticated: boolean = false;
  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(error: any) {
    this.error = error;
  }

  @Mutation
  setResponse(payload: any) {
    this.response = payload;
  }

  @Mutation
  setIsLoginInvalid(payload: boolean | null) {
    this.isLoginInvalid = payload;
  }

  @Mutation
  setIsAuthenticated(payload: boolean) {
    this.isAuthenticated = payload;
  }

  @Action
  resetState() {
    this.context.commit('setIsAuthenticated', false);
  }

  @Action
  async loginUser(params: LoginParams) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    this.context.commit('setIsLoginInvalid', null);
    try {
      // TODO: Set things up once backend is done.
      const response = await axios.post<LoginResponse>(
        Endpoints.Signin,
        params
      );
      if (response.status === 201) {
        this.context.commit('setIsLoginInvalid', false);
        this.context.commit('setIsAuthenticated', true);
        ToastProgrammatic.open({
          message: 'Login Successfully!',
          type: 'is-success',
          duration: 3000,
        });
      } else {
        ToastProgrammatic.open({
          message: 'Login Failed!',
          type: 'is-danger',
          duration: 3000,
        });
        this.context.commit('setIsLoginInvalid', true);
        this.context.commit('setIsAuthenticated', false);
      }
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async verifyAuth() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      const response = await axios.get(Endpoints.Authentication);
      if (response.status === 200) {
        this.context.commit('setIsAuthenticated', true);
      } else {
        this.context.commit('setIsAuthenticated', false);
      }
    } catch (error) {
      this.context.commit('updateLoadingStatus', false);
      this.context.commit('setIsAuthenticated', false);
    }
  }
}

export default getModule(LoginStore);
