import { DropdownOption } from '@/models/swine/dropdown.model';
import { ReferenceType } from '@/models/swine/economic-calculation.model';

export const DEFAULT_COUNT_PER_PAGE = 10;

export const DEFAULT_PAGE_NUMBER = 1;

export const DEFAULT_ATTACHMENT_SELECTION_LIMIT = 5;

export const DEFAULT_USER_ROLES: DropdownOption[] = [
  {
    text: 'Viewer',
    value: 'viewer',
    uniqueKey: 'viewer',
  },
  {
    text: 'Auditor',
    value: 'auditor',
    uniqueKey: 'auditor',
  },
  {
    text: 'Admin',
    value: 'admin',
    uniqueKey: `admin`,
  },
  {
    text: 'Regional',
    value: 'regional',
    uniqueKey: `regional`,
  },
];

export const DEFAULT_USER_FILTERS: DropdownOption[] = [
  {
    text: 'All',
    value: '',
    uniqueKey: `all`,
  },
  ...DEFAULT_USER_ROLES,
];

export const DEFAULT_SELECT_OPTION: DropdownOption[] = [
  {
    text: 'Select',
    value: '',
    uniqueKey: `select`,
  },
];

export const DEFAULT_CLIENT_COUNTRIES_CREATE: DropdownOption[] = [
  ...DEFAULT_SELECT_OPTION,
  {
    text: 'Malaysia',
    value: '1',
    uniqueKey: `malaysia_1`,
  },
  {
    text: 'Philippines',
    value: '2',
    uniqueKey: `philippines_2`,
  },
  {
    text: 'Thailand',
    value: '3',
    uniqueKey: `thailand_3`,
  },
  {
    text: 'Vietnam',
    value: '4',
    uniqueKey: `vietnam_4`,
  },
  {
    text: 'Indonesia',
    value: '5',
    uniqueKey: 'indonesia_5',
  },
];

export const REPORT_TYPE = {
  MOCK: 'mock',
  NORMAL: 'normal',
};

export const MONTHS: DropdownOption[] = [
  {
    text: `Jan`,
    value: `1`,
    uniqueKey: `jan_1`,
  },
  {
    text: `Feb`,
    value: `2`,
    uniqueKey: `feb_2`,
  },
  {
    text: `Mar`,
    value: `3`,
    uniqueKey: `mar_3`,
  },
  {
    text: `Apr`,
    value: `4`,
    uniqueKey: `apr_4`,
  },
  {
    text: `May`,
    value: `5`,
    uniqueKey: `may_5`,
  },
  {
    text: `Jun`,
    value: `6`,
    uniqueKey: `jun_6`,
  },
  {
    text: `Jul`,
    value: `7`,
    uniqueKey: `jul_7`,
  },
  {
    text: `Aug`,
    value: `8`,
    uniqueKey: `aug_8`,
  },
  {
    text: `Sep`,
    value: `9`,
    uniqueKey: `sep_9`,
  },
  {
    text: `Oct`,
    value: `10`,
    uniqueKey: `oct_10`,
  },
  {
    text: `Nov`,
    value: `11`,
    uniqueKey: `nov_11`,
  },
  {
    text: `Dec`,
    value: `12`,
    uniqueKey: `dec_12`,
  },
];

export const BASE_YEAR = 2017;
export const YEAR_LIMIT = 20;

export const ReportType = {
  MOCK: 'mock',
  NORMAL: 'normal',
};

export const AuditReportSections = {
  FARM_RESULT_SUMMARY: 'farm_result_summary',
  BRONCHOPNEUMONIA_SCORE: 'bronchopneumonia_score',
  PLEURISY: 'pleurisy',
  APP_INDEX_AND_PREVALENCE: 'app_index_and_prevalence',
  ABSCESS_SCARRING_PERICARDITIS: 'abscess_scarring_pericarditis',
  ECONOMIC_CALCULATION: 'economic_calculation',
  ATTACHMENTS: `attachments`,
};

export const AnalysisCriteriaSections = {
  MISCELLANEOUS: `miscellaneous`,
  MONTH: `month`,
};

export const AnalysisDropdownSections = {
  LUNG_LESION_SCORE_SUMMARY: `lung_lesion_score_summary`,
  BRONCHOPNEUMONIA_SCORE: `bronchopneumonia_score`,
  PNEUMONIA_INDEX_AND_PREVALENCE: `pneumonia_index_and_prevalence`,
  PLEURISY: `pleurisy`,
  APP_INDEX_AND_PREVALENCE: `app_index_and_prevalence`,
  ABSCESS_SCARRING_PERICARDITIS: `abscess_scarring_pericarditis`,
};

export const ANALYSIS_DROPDOWN_SECTIONS: DropdownOption[] = [
  {
    text: `Lung Lesion Score Summary`,
    value: `lung_lesion_score_summary`,
    uniqueKey: `lung_lesion_score_summary`,
  },
  {
    text: `Bronchopneumonia Score`,
    value: `bronchopneumonia_score`,
    uniqueKey: `bronchopneumonia_score`,
  },
  {
    text: `Pneumonia Index and Prevalence`,
    value: `pneumonia_index_and_prevalence`,
    uniqueKey: `pneumonia_index_and_prevalence`,
  },
  {
    text: `Pleurisy`,
    value: `pleurisy`,
    uniqueKey: `pleurisy`,
  },
  {
    text: `Dorsal Pleurisy Index and Prevalence`,
    value: `app_index_and_prevalence`,
    uniqueKey: `app_index_and_prevalence`,
  },
  {
    text: `Abscess, Scarring, Pericarditis`,
    value: `abscess_scarring_pericarditis`,
    uniqueKey: `abscess_scarring_pericarditis`,
  },
];

export const ANALYSIS_SECTION_COMPARISONS: {
  [key: string]: DropdownOption[];
} = {};
ANALYSIS_SECTION_COMPARISONS[`lung_lesion_score_summary`] = [
  {
    text: `Miscellaneous`,
    value: `miscellaneous`,
    uniqueKey: `miscellaneous_lung_lesion_score_summary`,
  },
  {
    text: `Month`,
    value: `month`,
    uniqueKey: `month_lung_lesion_score_summary`,
  },
];
ANALYSIS_SECTION_COMPARISONS[`bronchopneumonia_score`] = [
  {
    text: `Miscellaneous`,
    value: `miscellaneous`,
    uniqueKey: `miscellaneous_bronchopneumonia_score`,
  },
  { text: `Month`, value: `month`, uniqueKey: `miscellaneous_month` },
];
ANALYSIS_SECTION_COMPARISONS[`pneumonia_index_and_prevalence`] = [
  {
    text: `Miscellaneous`,
    value: `miscellaneous`,
    uniqueKey: `miscellaneous_pneumonia_index_and_prevalence`,
  },
  {
    text: `Month`,
    value: `month`,
    uniqueKey: `month_pneumonia_index_and_prevalence`,
  },
];
ANALYSIS_SECTION_COMPARISONS[`pleurisy`] = [
  {
    text: `Miscellaneous`,
    value: `miscellaneous`,
    uniqueKey: `miscellaneous_pleurisy`,
  },
  { text: `Month`, value: `month`, uniqueKey: `month_pleurisy` },
];
ANALYSIS_SECTION_COMPARISONS[`app_index_and_prevalence`] = [
  {
    text: `Miscellaneous`,
    value: `miscellaneous`,
    uniqueKey: `miscellaneous_app_index_and_prevalence`,
  },
  {
    text: `Month`,
    value: `month`,
    uniqueKey: `month_app_index_and_prevalence`,
  },
];
ANALYSIS_SECTION_COMPARISONS[`abscess_scarring_pericarditis`] = [
  {
    text: `Miscellaneous`,
    value: `miscellaneous`,
    uniqueKey: `miscellaneous_abscess_scarring_pericarditis`,
  },
  {
    text: `Month`,
    value: `month`,
    uniqueKey: `month_abscess_scarring_pericarditis`,
  },
];

export const ADD_COMPARISONS_DATA: DropdownOption[] = [
  {
    text: `Farm Unit`,
    value: `farm_unit`,
    uniqueKey: `farm_unit`,
  },
  {
    text: `Industry Average`,
    value: `industry_average`,
    uniqueKey: `industry_average`,
  },
  {
    text: `Individual Report`,
    value: `individual_report`,
    uniqueKey: `individual_report`,
  },
];

export const VARIABLES_TO_WORDS: { [key: string]: string } = {
  // Lung Lesion Score Summary
  bronchopneumoniaLungs: `Bronchopneumonia Lungs`,
  averageAffectedLungsSurfaceWithActivePneumonia: `Average Affected Lungs Surface With Active Pneumonia (%)`,
  cranialPleurisy: `Cranial Pleurisy (P1)`,
  dorsocaudalPleurisy: `Dorsocaudal Pleurisy (P2+P3+P4)`,
  abscess: `Abscess`,
  scarring: `Scarring`,
  pericarditis: `Pericarditis`,
  // App Index And Prevalence
  appIndex: `Dorsal Pleurisy Index`,
  appPleurisyPrevalence: `Dorsal Pleurisy Prevalence`,
  pleurisyPrevalence: `Pleurisy Prevalence`,
  // Pneumonia Index and Prevalence
  pneumoniaIndex: `Pneumonia Index`,
  pneumoniaPrevalence: `Pneumonia Prevalence`,
  bronchopneumoniaPrevalence: `Bronchopneumonia Prevalence`,
  averageAffectedSurfaceOutOfLungs: `Average Affected Surface Out Of Lungs`,
  averageAffectedSurfaceOutOfLungsWithActivePneumonia: `Average Affected Surface Out of Lungs with Active Pneumonia`,
  // Pleurisy
  P0: `Pleuritis 0`,
  P1: `Pleuritis 1`,
  P2: `Pleuritis 2`,
  P3: `Pleuritis 3`,
  P4: `Pleuritis 4`,
  // Bronchopneumonia Score
  pneumonia0: `Normal Lung (0%)`,
  pneumonia1: `Normal Lung (1-10%)`,
  pneumonia2: `Pneumonia (11-20%)`,
  pneumonia3: `Pneumonia (21-30%)`,
  pneumonia4: `Pneumonia (31-40%)`,
  pneumonia5: `Severe Pneumonia (41-50%)`,
  pneumonia6: `Severe Pneumonia (>50%)`,
};

export const ANALYSIS_COLORS = {
  LUNG_LESION_SCORE_SUMMARY: {
    BRONCHOPNEUMONIA_LUNGS: {
      BACKGROUND: `#D6FFA4`,
      BORDER: `#547230`,
    },
    AVERAGE_AFFECTED_LUNGS_SURFACE_WITH_ACTIVE_PNEUMONIA: {
      BACKGROUND: `#99e895`,
      BORDER: `#547230`,
    },
    CRANIAL_PLEURISY: {
      BACKGROUND: `#fff5c9`,
      BORDER: `#745e05`,
    },
    DORSOCAUDAL_PLEURISY: {
      BACKGROUND: `#ffd16a`,
      BORDER: `#745e05`,
    },
    ABSCESS: {
      BACKGROUND: `#ffeaea`,
      BORDER: `#ff4c4b`,
    },
    SCARRING: {
      BACKGROUND: `#ffc1c1`,
      BORDER: `#ff4c4b`,
    },
    PERICARDITIS: {
      BACKGROUND: `#d9f2ff`,
      BORDER: `#00a5e1`,
    },
  },
  BRONCHOPNEUMONIA_SCORE: {
    PNEUMONIA_0: {
      BACKGROUND: `#D6FFA4`,
      BORDER: `#547230`,
    },
    PNEUMONIA_1: {
      BACKGROUND: `#99e895`,
      BORDER: `#547230`,
    },
    PNEUMONIA_2: {
      BACKGROUND: `#fff5c9`,
      BORDER: `#745e05`,
    },
    PNEUMONIA_3: {
      BACKGROUND: `#ffd16a`,
      BORDER: `#745e05`,
    },
    PNEUMONIA_4: {
      BACKGROUND: `#FFF084`,
      BORDER: `#745e05`,
    },
    PNEUMONIA_5: {
      BACKGROUND: `#ffeaea`,
      BORDER: `#ff4c4b`,
    },
    PNEUMONIA_6: {
      BACKGROUND: `#ffc1c1`,
      BORDER: `#ff4c4b`,
    },
    PNEUMONIA_PREVALENCE: {
      BACKGROUND: `#ffeaea`,
      BORDER: `#ff4c4b`,
    },
  },
  PNEUMONIA_INDEX_AND_PREVALENCE: {
    PNEUMONIA_PREVALENCE: {
      BACKGROUND: `#ffeaea`,
      BORDER: `#ff4c4b`,
    },
    BRONCHOPNEUMONIA_PREVALENCE: {
      BACKGROUND: `#ecd9ff`,
      BORDER: `#6b3c97`,
    },
    AVERAGE_AFFECTED_SURFACE_OUT_OF_LUNGS: {
      BACKGROUND: `#E2FFFA`,
      BORDER: `#00A5E1`,
    },
    AVERAGE_AFFECTED_SURFACE_OUT_OF_LUNGS_WITH_ACTIVE_PNEUMONIA: {
      BACKGROUND: `#0072ce`,
      BORDER: `#0072ce`,
    },
  },
  PLEURISY: {
    P0: {
      BACKGROUND: `#D6FFA4`,
      BORDER: `#547230`,
    },
    P1: {
      BACKGROUND: `#fff5c9`,
      BORDER: `#745E05`,
    },
    P2: {
      BACKGROUND: `#ffeaea`,
      BORDER: `#ff4c4b`,
    },
    P3: {
      BACKGROUND: `#ffd4d4`,
      BORDER: `#ff4c4b`,
    },
    P4: {
      BACKGROUND: `#ffc1c1`,
      BORDER: `#ff4c4b`,
    },
    PLEURISY_PREVALENCE: {
      BACKGROUND: `#0072ce`,
      BORDER: `#0072ce`,
    },
  },
  APP_INDEX_AND_PREVALENCE: {
    APP_PLEURISY_PREVALENCE: {
      BACKGROUND: `#ecd9ff`,
      BORDER: `#6b3c97`,
    },
    PLEURISY_PREVALENCE: {
      BACKGROUND: `#0072ce`,
      BORDER: `#0072ce`,
    },
  },
  ABSCESS_SCARRING_PERICARDITIS: {
    ABSCESS: {
      BACKGROUND: `#D6FFA4`,
      BORDER: `#547230`,
    },
    SCARRING: {
      BACKGROUND: `#fff5c9`,
      BORDER: `#745e05`,
    },
    PERICARDITIS: {
      BACKGROUND: `#ffeaea`,
      BORDER: `#ff4c4b`,
    },
  },
};

export const EXPORT_REPORT_OPTION: DropdownOption[] = [
  {
    text: `Export PDF`,
    value: `export_pdf`,
    uniqueKey: `export_pdf`,
  },
  {
    text: `Export Raw Data Excel`,
    value: `export_excel`,
    uniqueKey: `export_excel`,
  },
  {
    text: `Export Summary Report Excel`,
    value: `export_summary_excel`,
    uniqueKey: `export_summary_excel`,
  },
];

export enum Role {
  Viewer = 'viewer',
  Auditor = 'auditor',
  Admin = 'admin',
  Regional = 'regional',
}
export const ReferenceTypeAdgReductionPercentages = {
  [ReferenceType.E_STERVERMER]: [0.0, 0.03, 0.08, 0.15, 0.19, 0.24, 0.3],
  [ReferenceType.PIG_SITE]: [0.0, 0.0, 0.06, 0.18, 0.26, 0.5, 0.5],
};

export const ECONOMIC_CALCULATION_MEDICATION = {
  CLEAN_SOW_FEED_INTAKE: 5.5,
  CLEAN_SOW_DURATION: 21,
  CLEAN_SOW_PS_RATIO: 1.6,
  START_STRONG_FEED_INTAKE: 0.5,
  START_STRONG_DURATION: 21,
  STRATEGIC_FEED_INTAKE: 1.5,
  STRATEGIC_DURATION: 21,
};

export const SwineAuditTypes = [{ name: 'Lung Lesion Scoring', value: 1 }];

export const PoultryModuleTypes = [
  {
    name: 'Poultry',
    value: 4,
    disabled: false,
  },
];

export const PoultryAuditTypes = [
  {
    name: 'Drinking Water Vaccination',
    value: 2,
  },
  {
    name: 'Injectable Vaccination',
    value: 3,
  },
  {
    name: 'Hatchery Spray',
    value: 4,
  },
  {
    name: 'Cleaning and Disinfection',
    value: 5,
  },
  {
    name: 'Integrated Pest Management',
    value: 6,
  },
  {
    name: 'Lab on Wheel Audits',
    value: 7,
  },
  {
    name: 'Pipped Embryo Analysis',
    value: 8,
  },
  {
    name: 'Egg Break Out Analysis',
    value: 9,
  },
];

export const SwineModuleTypes = [
  {
    name: 'Lung Lesion Scoring',
    value: 1,
    disabled: false,
  },
  {
    name: 'Bio Protection',
    value: 2,
    disabled: true,
  },
  {
    name: 'Learning Hub',
    value: 3,
    disabled: true,
  },
];

export enum Species {
  Swine = 'Swine',
  Poultry = 'Poultry',
}

export enum Modules {
  LungLesionScoring = 'Lung Lesion Scoring',
  BioProtection = 'Bio Protection',
  LeaningHub = 'Learning Hub',
}

export enum EconomicCalculationType {
  select = `Select Economic Calculation Type`,
  pulmotil = `Economic Value Calculator (Standard)`,
  pleurisy = `Economic Value Calculator (Malaysia)`,
}

export const DEFAULT_SPECIES: DropdownOption[] = [
  {
    text: 'Swine',
    value: 'swine',
    uniqueKey: 'swine',
  },
  {
    text: 'Poultry',
    value: 'poultry',
    uniqueKey: 'poultry',
  },
];

export const DEFAULT_SPECIES_OPTIONS: DropdownOption[] = [
  {
    text: 'All',
    value: '',
    uniqueKey: `all`,
  },
  ...DEFAULT_SPECIES,
];
