import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { UserSessionDetails } from '@/models/swine/user/user.model';

@Module({
  namespaced: true,
  dynamic: true,
  name: 'user-session.detail',
  store,
})
class UserSessionDetail extends VuexModule {
  response: UserSessionDetails | null = null;
  isLoading = false;
  error: any | null = null;
  noRedirect = false;

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(error: any) {
    this.error = error;
  }

  @Mutation
  setResponse(payload: UserSessionDetails) {
    this.response = payload;
  }

  @Mutation
  setNoRedirect(payload: boolean) {
    this.noRedirect = payload;
  }

  @Action
  getUserDetails() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios
        .get<UserSessionDetails>(`${Endpoints.Users}/profile`)
        .then((response) => this.context.commit('setResponse', response.data));
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(UserSessionDetail);
