import { User } from '@/models/poultry/user';
import jsCookie from 'js-cookie';

export enum COOKIE {
  TOKEN = '__SID',
  AccessToken = 'access_token',
  User = 'user'
}

export const cookie = jsCookie;

export function getAccessToken(): string | null {
  const token = cookie.get(COOKIE.TOKEN);

  if (!token) {
    return null;
  }

  return token;
}


export function getUser(): User | null {
  const user = cookie.get(COOKIE.User);

  if (!user) {
    return null;
  }

  return JSON.parse(user);
}
