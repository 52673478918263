import {
  Module,
  VuexModule,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import {
  ChangePasswordParams,
  UserProfileEdit,
} from '@/models/swine/login/user.model';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { ToastProgrammatic } from 'buefy';

@Module({
  namespaced: true,
  dynamic: true,
  name: 'user-profile.store',
  store,
})
class UserProfileStore extends VuexModule {
  response: any | null = null;
  isLoading = false;
  error: any | null = null;
  userProfile: UserProfileEdit | null = null;
  isUpdatePasswordValid = false;

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  updatePasswordValidStatus(payload: boolean) {
    this.isUpdatePasswordValid = payload;
  }

  @Mutation
  setError(error: any) {
    this.error = error;
  }

  @Mutation
  setResponse(payload: any) {
    this.response = payload;
  }

  @Mutation
  setUserProfile(payload: any) {
    this.userProfile = payload;
  }

  @Action
  resetState() {
    this.context.commit('setUserProfile', null);
  }

  @Action
  async getUserProfile() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      // TODO: Set things up once backend is done.
      const response = await axios.get(`${Endpoints.Users}/profile`);
      if (response.status === 200 && response.data) {
        const userProfile: UserProfileEdit = {
          role: response.data.role,
          name: response.data.name,
          phoneNumber: response.data.phoneNumber,
          email: response.data.email,
          country: response.data.country ? response.data.country : null,
          module: response.data.module,
        };
        this.context.commit('setUserProfile', userProfile);
      }
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async updateUserPassword(params: ChangePasswordParams) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      this.context.commit('updatePasswordValidStatus', false);
      const response = await axios.put(`${Endpoints.Users}/password`, params);
      if (response.status === 200 && response.data) {
        ToastProgrammatic.open({
          message: 'Password Updated',
          duration: 5000,
          type: 'is-dark',
        });
        const userProfile: UserProfileEdit = {
          role: response.data.role,
          name: response.data.name,
          phoneNumber: response.data.phoneNumber,
          email: response.data.email,
          country: response.data.country ? response.data.country : null,
          module: response.data.module,
        };
        this.context.commit('setUserProfile', userProfile);
        this.context.commit('updatePasswordValidStatus', true);
      } else {
        this.context.commit('updatePasswordValidStatus', false);
      }
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async updateUserProfile() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      const response = await axios.put(
        `${Endpoints.Profile}/`,
        this.userProfile,
      );
      if (response.status === 200 && response.data) {
        ToastProgrammatic.open({
          message: 'User Profile Updated',
          duration: 5000,
          type: 'is-dark',
        });
        const userProfile: UserProfileEdit = {
          role: response.data.role,
          name: response.data.name,
          phoneNumber: response.data.phoneNumber,
          email: response.data.email,
          country: response.data.country ? response.data.country : null,
          module: response.data.module,
        };
        this.context.commit('setUserProfile', userProfile);
      }
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(UserProfileStore);
