export enum Endpoints {
  // Delete any unnecessary
  Signin = '/accounts/login',
  Signout = '/accounts/logout',
  Profile = '/profile',
  AuditorsAutocomplete = '/auditors/autocomplete',
  Clients = '/clients',
  Countries = '/countries',
  Regions = '/regions',
  Cities = '/cities',
  Reports = '/audit_reports',
  ReportTypes = '/audit_types',
  ReportsTotalCount = '/audit_reports/total_count',
  Dashboard = '/dashboard',
  Farms = '/farms',
  FarmUnits = '/farm_units',
  Analysis = '/analysis',
  Dropdown = '/dropdown',
  //Currency Exchange
  OpenExchangeRateLatest = '/latest.json',
  FixerLatest = '/latest',
  // New endpoints
  Users = '/users',
  States = '/states',
  Attachments = '/attachments',
  ForgetPassword = '/accounts/forget_password',
  ResetPassword = '/accounts/reset_password',
  TokenValidation = '/accounts/token',
  RequestCode = '/request_code',
  Swine = '/swine',
  Authentication = '/profile/me',
  IndustryAverages = '/industry_averages',
  AnimalTypes='/animal_types',
  Seasons='/seasons',
  OperationTypes='/operation_types',
  HousingTypes='/housing_types',
  FlooringTypes='/flooring_types',
}
