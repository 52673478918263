import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Role, Species } from '@/utils/constants';
import { ToastProgrammatic } from 'buefy';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { UserSessionStore } from '@/store/swine/user-session';
import loginStore from '@/store/swine/login/login.store';
import userProfileStore from '@/store/swine/profile/user-profile.store';
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // General Routes
  {
    path: '/user_management',
    name: 'UserManagement',
    component: () =>
      import('@/views/general/userManagement/UserManagement.vue'),
    meta: {
      requireAuth: true,
      roles: [Role.Admin, Role.Regional, Role.Auditor, Role.Viewer],
      species: [Species.Poultry, Species.Swine],
      title: 'Elanco | User Management',
    },
  },
  {
    path: '/user_management/create',
    name: 'CreateUser',
    component: () => import('@/views/general/userManagement/CreateUser.vue'),
    meta: {
      requireAuth: true,
      roles: [Role.Admin, Role.Regional],
      species: [Species.Poultry, Species.Swine],
      title: 'Elanco | Create User',
    },
  },
  {
    path: '/user_management/:userId',
    name: 'EditUser',
    component: () => import('@/views/general/userManagement/EditUser.vue'),
    meta: {
      requireAuth: true,
      roles: [Role.Admin, Role.Regional, Role.Auditor, Role.Viewer],
      species: [Species.Poultry, Species.Swine],
      title: 'Elanco | Edit User',
    },
  },
  {
    path: '/forgot_password',
    name: 'ForgotPassword',
    component: () => import('@/views/general/login/ForgotPassword.vue'),
    meta: {
      requireAuth: false,
      title: 'Elanco | Forgot Password',
    },
  },
  {
    path: '/reset_password',
    name: 'ResetPassword',
    component: () => import('@/views/general/login/ResetPassword.vue'),
    meta: {
      requireAuth: false,
      title: 'Elanco | Reset Password',
    },
  },
  {
    path: '/general/edit_profile',
    name: 'GeneralEditUserProfile',
    component: () =>
      import('@/views/general/profile/GeneralEditUserProfile.vue'),
    meta: {
      requireAuth: true,
      roles: [Role.Admin, Role.Regional, Role.Auditor, Role.Viewer],
      species: [Species.Poultry, Species.Swine],
      title: 'Elanco | Edit Profile',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/general/login/Login.vue'),
    meta: {
      requireAuth: false,
      title: 'Elanco | Login',
    },
  },
  {
    path: '/',
    name: 'ModuleSelection',
    component: () =>
      import('@/views/general/moduleSelection/ModuleSelection.vue'),
    meta: {
      requireAuth: true,
      roles: [Role.Admin, Role.Regional, Role.Auditor, Role.Viewer],
      species: [Species.Poultry, Species.Swine],
      title: 'Elanco | Category Selection',
    },
  },
  // LLS Routes
  {
    path: '/swine',
    name: 'SwineDashboard',
    component: () => import('@/views/swine/Home.vue'),
    meta: {
      requireAuth: true,
      roles: [Role.Admin, Role.Regional, Role.Auditor, Role.Viewer],
      species: [Species.Swine],
      title: 'Elanco | Dashboard',
    },
  },
  {
    path: '/swine/edit_profile',
    name: 'SwineEditUserProfile',
    component: () => import('@/views/swine/profile/EditUserProfile.vue'),
    meta: {
      requireAuth: true,
      roles: [Role.Admin, Role.Regional, Role.Auditor, Role.Viewer],
      species: [Species.Swine],
      title: 'Elanco | Edit Profile',
    },
  },

  {
    path: '/swine/clients',
    name: 'ClientManagement',
    component: () => import('@/views/swine/client/ClientManagement.vue'),
    meta: {
      requireAuth: true,
      roles: [Role.Regional, Role.Admin, Role.Auditor, Role.Viewer],
      species: [Species.Swine],
      title: 'Elanco | Clients',
    },
  },
  {
    path: '/swine/clients/details/:clientId',
    name: 'ClientSummary',
    component: () => import('@/views/swine/client/ClientSummary.vue'),
    meta: {
      requireAuth: true,
      roles: [Role.Admin, Role.Regional, Role.Auditor, Role.Viewer],
      species: [Species.Swine],
      title: 'Elanco | Client Summary',
    },
  },
  {
    path: '/swine/clients/edit/:clientId',
    name: 'EditClient',
    component: () => import('@/views/swine/client/EditClient.vue'),
    meta: {
      requireAuth: true,
      roles: [Role.Regional, Role.Admin, Role.Auditor],
      species: [Species.Swine],
      title: 'Elanco | Edit Client',
    },
  },
  {
    path: '/swine/clients/create',
    name: 'CreateClient',
    component: () => import('@/views/swine/client/CreateClient.vue'),
    meta: {
      requireAuth: true,
      roles: [Role.Admin, Role.Regional, Role.Auditor, Role.Viewer],
      species: [Species.Swine],
      title: 'Elanco | Create Client',
    },
  },
  {
    path: '/swine/reports',
    name: 'ReportManagement',
    component: () => import('@/views/swine/report/ReportManagement.vue'),
    meta: {
      requireAuth: true,
      roles: [Role.Admin, Role.Regional, Role.Auditor, Role.Viewer],
      species: [Species.Swine],
      title: 'Elanco | Reports',
    },
  },
  {
    path: '/swine/reports/:reportId',
    name: 'ReportDetails',
    component: () => import('@/views/swine/report/ReportDetails.vue'),
    meta: {
      requireAuth: true,
      roles: [Role.Admin, Role.Regional, Role.Auditor, Role.Viewer],
      species: [Species.Swine],
      title: 'Elanco | Report',
    },
  },
  {
    path: '/swine/analysis',
    name: 'Analysis',
    component: () => import('@/views/swine/analysis/Analysis.vue'),
    meta: {
      requireAuth: true,
      roles: [Role.Admin, Role.Regional, Role.Auditor, Role.Viewer],
      species: [Species.Swine],
      title: 'Elanco | Analysis',
    },
  },
  // GVP routes
  {
    path: '/poultry',
    name: 'PoultryDashboard',
    component: () => import('@/views/poultry/Dashboard/Dashboard.vue'),
    meta: {
      requireAuth: true,
      roles: [Role.Admin, Role.Regional],
      species: [Species.Poultry],
      title: 'Elanco | Dashboard',
    },
  },
  {
    path: '/poultry/clients',
    name: 'clients',
    component: () => import('@/views/poultry/Client/Client.vue'),
    meta: {
      requireAuth: true,
      species: [Species.Poultry],
      roles: [Role.Admin, Role.Regional],
      title: 'Elanco | Clients',
    },
  },
  {
    path: '/poultry/clients/:id/',
    name: 'client.details',
    meta: {
      requireAuth: true,
      species: [Species.Poultry],
      roles: [Role.Admin, Role.Regional],
      title: 'Elanco | Clients',
    },
    redirect: {
      name: 'client.details.farms',
    },
    component: () => import('@/views/poultry/Client/ClientDetails.vue'),
    children: [
      {
        path: '',
        name: 'client.details.farms',
        meta: {
          requireAuth: true,
          species: [Species.Poultry],
          roles: [Role.Admin, Role.Regional],
          title: 'Elanco | Farms',
        },
        component: () => import('@/views/poultry/Client/Farm/Farm.vue'),
      },
      {
        path: 'auditors',
        name: 'client.details.auditors',
        meta: {
          requireAuth: true,
          species: [Species.Poultry],
          roles: [Role.Admin, Role.Regional],
          title: 'Elanco | Auditors',
        },
        component: () => import('@/views/poultry/Client/Auditor/Auditor.vue'),
      },
    ],
  },
  {
    path: '/poultry/reports',
    name: 'reports',
    meta: {
      requireAuth: true,
      species: [Species.Poultry],
      roles: [Role.Admin, Role.Regional],
      title: 'Elanco | Reports',
    },
    component: () => import('@/views/poultry/Reports/Reports.vue'),
  },
  {
    path: '/poultry/reports/:id',
    name: 'reports.details',
    meta: {
      requireAuth: true,
      species: [Species.Poultry],
      roles: [Role.Admin, Role.Regional],
      title: 'Elanco | Reports',
    },
    component: () =>
      import('@/views/poultry/Reports/AuditScore/AuditScoreResults.vue'),
  },
  {
    path: '/poultry/analysis',
    name: 'analysis',
    meta: {
      requireAuth: true,
      species: [Species.Poultry],
      roles: [Role.Admin, Role.Regional],
      title: 'Elanco | Analysis',
    },
    redirect: {
      name: 'analysis.results',
    },
    component: () => import('@/views/poultry/Analysis/Analysis.vue'),
    children: [
      {
        path: '',
        name: 'analysis.results',
        meta: {
          requireAuth: true,
          species: [Species.Poultry],
          roles: [Role.Admin, Role.Regional],
        },
        component: () =>
          import(
            '@/views/poultry/Analysis/ResultsAnalysis/ResultsAnalysis.vue'
          ),
      },
      {
        path: 'preparation',
        name: 'analysis.preparation',
        meta: {
          requireAuth: true,
          species: [Species.Poultry],
          roles: [Role.Admin, Role.Regional],
        },
        component: () =>
          import(
            '@/views/poultry/Analysis/PreparationAnalysis/PreparationAnalysis.vue'
          ),
      },
      {
        path: 'vaccination',
        name: 'analysis.vaccination',
        meta: {
          requireAuth: true,
          species: [Species.Poultry],
          roles: [Role.Admin, Role.Regional],
        },
        component: () =>
          import(
            '@/views/poultry/Analysis/VaccinationAnalysis/VaccinationAnalysis.vue'
          ),
      },
      {
        path: 'hatchery',
        name: 'analysis.hatchery',
        meta: {
          requireAuth: true,
          species: [Species.Poultry],
          roles: [Role.Admin, Role.Regional],
        },
        component: () =>
          import(
            '@/views/poultry/Analysis/HatcheryAnalysis/HatcheryAnalysis.vue'
          ),
      },
      {
        path: 'visual',
        name: 'analysis.visual',
        meta: {
          requireAuth: true,
          species: [Species.Poultry],
          roles: [Role.Admin, Role.Regional],
        },
        component: () =>
          import('@/views/poultry/Analysis/VisualAnalysis/VisualAnalysis.vue'),
      },
      {
        path: 'swab_sample_collection',
        name: 'analysis.swab_samples',
        meta: {
          requireAuth: true,
          species: [Species.Poultry],
          roles: [Role.Admin, Role.Regional],
        },
        component: () =>
          import(
            '@/views/poultry/Analysis/SwabSampleAnalysis/SwabSampleAnalysis.vue'
          ),
      },
    ],
  },
  {
    path: '/poultry/terms-of-service',
    name: 'terms.of.service',
    meta: {
      public: true,
      title: 'Elanco | Terms of Service',
    },
    component: () => import('@/views/poultry/TermsOfService.vue'),
  },
  {
    path: '/poultry/privacy-policy',
    name: 'privacy.policy',
    meta: {
      public: true,
      title: 'Elanco | Private Policy',
    },
    component: () => import('@/views/poultry/PrivacyPolicy.vue'),
  },
  {
    path: '/poultry/faq',
    name: 'faq',
    meta: {
      public: true,
      title: 'Elanco | FAQ',
    },
    component: () => import('@/views/poultry/Faq.vue'),
  },
  {
    path: '/poultry/profile',
    name: 'profile',
    meta: {
      requireAuth: true,
      species: [Species.Poultry],
      roles: [Role.Admin, Role.Regional],
      title: 'Elanco | Profile',
    },
    component: () => import('@/views/poultry/Profile/Profile.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// let currentRole: string | null = null;
router.beforeEach(async (to, _, next) => {
  document.title = to.meta && to.meta.title ? to.meta.title : 'Elanco';

  let isAuthenticated = loginStore.isAuthenticated;
  if (
    !isAuthenticated &&
    to.path !== '/reset_password' &&
    to.path !== '/forgot_password'
  ) {
    await loginStore.verifyAuth();
    isAuthenticated = loginStore.isAuthenticated;
  }

  if (
    userProfileStore.userProfile?.role === undefined ||
    (isAuthenticated && userProfileStore.userProfile?.role.length === 0)
  ) {
    await userProfileStore.getUserProfile();
  }
  // Not authenticated
  if (to.meta!.requireAuth && !isAuthenticated) {
    next({ name: `Login` });
  }
  // Authenticated, role checking and species checking
  else if (to.meta!.requireAuth && isAuthenticated && to.meta!.roles) {
    const isRoleValid = to.meta!.roles.some((role: string) => {
      return role === userProfileStore.userProfile?.role;
    });
    let isValidSpeciesAccess: boolean = true;
    if (to.meta!.species) {
      const userSpeciesAccess = userProfileStore.userProfile?.module.map(
        (species) => species.speciesName
      );
      isValidSpeciesAccess = to.meta!.species.some((species: string) => {
        return userSpeciesAccess?.includes(species);
      });
    }

    // If the user's role have access to screen
    if (isRoleValid && isValidSpeciesAccess) {
      next();
    } else {
      ToastProgrammatic.open({
        message: 'You do not have sufficient permission to access this page',
        duration: 3000,
        type: 'is-dark',
      });
      next({ name: 'ModuleSelection' });
    }
  }
  // Authenticated, but without any authorization
  else {
    next();
  }
});

export default router;
