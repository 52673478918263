export interface PaginationModel {
  offset: number;
  limit: number;
  totalPages?: number;
  totalRecords?: number;
}

export enum PaginationHeader {
  Page = 'X-Page',
  Limit = 'X-Limit',
  TotalPages = 'X-Total-Pages',
  TotalRecords = 'X-Total-Records',
}

export function serializePaginationFromHeader(headers: {
  [key: string]: string;
}): PaginationModel {
  const { Page, Limit, TotalPages, TotalRecords } = PaginationHeader;

  const offset = Number(headers[Page.toLowerCase()]);
  const limit = Number(headers[Limit.toLowerCase()]);
  const totalPages = Number(headers[TotalPages.toLowerCase()]);
  const totalRecords = Number(headers[TotalRecords.toLowerCase()]);

  return {
    offset,
    limit,
    totalPages,
    totalRecords,
  };
}
export function getRowNumber(pagination: PaginationModel): number {
  if (pagination.offset === 1) {
    return 1;
  }

  return pagination.limit * pagination.offset - pagination.limit + 1;
}
