













import { Component, Vue } from 'vue-property-decorator';
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { axios } from './utils/axios';
@Component({
  components: {},
})
export default class App extends Vue {
  activeRequests = 0;

  get hasActiveRequests(): boolean {
    return this.activeRequests > 0;
  }

  created() {
    const requestInterceptor = (config: AxiosRequestConfig) => {
      this.activeRequests++;
      return config;
    };

    const responseInterceptor = (response: AxiosResponse) => {
      this.activeRequests--;
      return response;
    };

    const errorInterceptor = (error: AxiosError) => {
      this.activeRequests--;
      return Promise.reject(error);
    };

    axios.interceptors.request.use(requestInterceptor);
    axios.interceptors.response.use(responseInterceptor, errorInterceptor);
  }
}
